import { browser } from "$app/environment";
import type { Intent } from "$lib/intents";
import { getIdByName } from "$lib/products";
import { getSubscriber, type User } from "$lib/subscription-service";
import { loadUser, unloadUser } from "$lib/utils";
import DOMPurify from "isomorphic-dompurify";
import type { LayoutLoad } from "./$types";
import { base } from "$app/paths";

/**
 * Set page configuration options
 */
export const prerender = true;
export const trailingSlash = "always";
export const ssr = true;

/**
 * Store receipt in localStorage for receipt linking
 */
if (browser) {
  const vpnReceiptBlob = sessionStorage.getItem("braveVpn.receipt");
  if (vpnReceiptBlob) {
    localStorage.setItem("braveVpn.receipt", vpnReceiptBlob);
  }

  const leoReceipt = sessionStorage.getItem("braveLeo.receipt");
  if (leoReceipt) {
    localStorage.setItem("braveLeo.receipt", leoReceipt);
  }

  const leoOrderId = sessionStorage.getItem("braveLeo.orderId");
  if (leoOrderId) {
    localStorage.setItem("braveLeo.orderId", leoOrderId);
  }
}

/** Set up default Trusted Type */
if (browser && window.trustedTypes) {
  // @ts-ignore
  trustedTypes.createPolicy("default", {
    createHTML: (dirty) => DOMPurify.sanitize(dirty, { RETURN_TRUSTED_TYPE: true }),
    createScriptURL: (urlStr) => {
      const url = new URL(urlStr);
      if (url.origin === "https://js.stripe.com") {
        return urlStr;
      }
    },
  });
}

/**
 * Load global data for pages
 */
export const load: LayoutLoad = async ({ url }) => {
  if (!browser) return;

  const orderId = url.searchParams.get("order_id");
  const intent = url.searchParams.get("intent") as Intent;
  const productKey = url.searchParams.get("product");
  const productId = url.searchParams.get("product_id") || (productKey && getIdByName(productKey));
  const requestId = url.searchParams.get("requestId");
  const code = url.searchParams.get("code");

  /**
   * Intentionally not using the load function's url param in order to 'refetch'
   * from URL every time. Due (ostensibly) to the beforeNavigation handling
   * in +layout.svelte which mutates the to.url.search property, the load function's
   * url param seems to be stale after the mutation.
   */
  const uxMode: "mobile" | any = (new URLSearchParams(location.search)).get("ux") ?? undefined;

  /**
   * We can safely assume that the user is confirming the log-in action if
   * A. the pathname is the root URL,
   * B. and the URL has both the requestId and code query params.
   */
  const isConfirmingLogin = url.pathname === (base || "/") && requestId && code;

  let user: User;
  try {
    /**
     * We don't want to bother checking for a subscriber if it's clear
     * that the user is attempting to confirm their log-in action via
     * the email roundtrip.
     */
    if (!isConfirmingLogin) {
      user = await getSubscriber();
      loadUser(user);
    }
  } catch (_) {
    unloadUser();
  }

  return {
    orderId,
    intent,
    productKey,
    productId,
    user,
    uxMode,
    requestId,
    code,
  };
};
